/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-16 16:13:35
 * @Module: 预存款
 */
 <template>
  <div class="deposit">
    <div class="title">
      <span>预存款</span>
      <span>
        <el-button type="primary" size="small" v-if="['ADMIN','SUPER','LEGAL'].includes(user.role)" @click="addDepositForm">添加预存款</el-button>
      </span>
    </div>
    <!-- <div style="padding:16px;">
      <el-table row-key="uuid" :data="list" size="mini">
        <el-table-column label="金额" prop="amount" width="50">
          <template slot-scope="scope">
            <span>{{scope.row.amount ||'--'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="余额" prop="balance" width="50">
          <template slot-scope="scope">
            <span>{{scope.row.balance ||'--'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="日期" prop="date" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.date">{{ scope.row.date|timeFormat }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="note">
          <template slot-scope="scope">
            <span>{{scope.row.note ||'--'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" v-if="user.role==='ADMIN'" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="()=>revisionDepositForm(scope.row)">编辑</el-button>
            <el-button type="text" size="mini" @click="()=>deleteDeposit(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div> -->
    <div class="content">
      <div class="item" v-for="item in list" :key="item.uuid">
        <div class="item-t">
          <span class="item-t-l">
            {{ item.date|timeFormat }}
          </span>
          <span class="item-t-r" :class="item.amount>0?'primary':'error'">{{item.amount|priceFormat(2)}}</span>
        </div>
        <div class="item-c">
          <span class="item-c-l">备注：</span>
          <span class="item-c-r">余额：{{item.balance|priceFormat(2)}}</span>
        </div>
        <div class="item-b">
          <span class="item-b-l">{{item.note ||'--'}}</span>
          <el-dropdown size="medium" @command="handleCommand" v-if="['ADMIN','SUPER','LEGAL'].includes(user.role)">
            <span class="item-b-r pointer primary">更多</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{data:item,fn:'revisionDepositForm'}">编辑</el-dropdown-item>
              <el-dropdown-item :command="{data:item,fn:'deleteDeposit'}">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>
      </div>
    </div>
    <depositForm @success="success" ref="depositForm" />
  </div>
</template>
 <script>
import { mapState } from "vuex";
import depositForm from "./depositForm.vue"
export default {
  components: {
    depositForm
  },
  data () {
    return {
      list: []
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  mounted () {
    this.getDepositList()
  },
  methods: {
    // 修改添加成功
    success () {
      this.getDepositList();
      this.$emit('success')
    },
    async getDepositList () {
      const { uuid } = this.$route.params
      const { status, data } = await this.$api.getDepositList({ companyUuid: uuid });
      if (status === 200) {
        this.list = data
      }
    },
    //修改预存款
    revisionDepositForm (data) {
      this.$refs.depositForm.show({ type: 'revision', data });
    },
    // 添加预存款
    addDepositForm () {
      this.$refs.depositForm.show({ type: 'add' })
    },
    // 删除预存款
    async deleteDeposit ({ uuid }) {
      try {
        await this.$confirm(`此操作将永久删除这条记录，是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteDeposit({ uuid })
        if (status == 200) {
          this.$message({
            message: info,
            type: 'success'
          })
          this.getDepositList()
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      }

    },
    handleCommand ({ fn, data }) {
      this[fn](data)
    }
  },
};
 </script>
 <style lang='scss' scoped>
.deposit {
  background: #fff;
  .title {
    line-height: 56px;
    padding: 0 16px;
    font-size: 16px;
    color: #262626;
    display: flex;
    justify-content: space-between;
    .btn {
      font-size: 14px;
      color: #0d72ff;
    }
  }
  .content {
    padding: 0 16px;
    padding-bottom: 16px;
    .item {
      height: 103px;
      padding: 16px 0;
      font-size: 14px;
      line-height: 21px;
      border-bottom: 1px solid #eee;
      &:last-child {
        border-bottom: none;
      }
      &-t,
      &-c,
      &-b {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-t {
        &-l {
          color: #262626;
        }
      }
      &-c {
        color: #999999;
        margin: 4px 0;
      }
      &-b {
        color: #666;
        &-r {
          text-decoration-line: underline;
        }
      }
    }
  }
}
</style>
/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-10 11:15:17
 * @Module: 添加动态
 */
 <template>
  <div>
    <el-dialog title="添加动态" :visible.sync="dialogVisible" width="40%">
      <div style="padding:20px;">
        <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
          <el-form-item label="动态内容" prop="content" :rules="{ required: true, message: '动态内容不能为空'}">
            <el-input v-model="form.content" :autosize="{minRows: 4, maxRows: 12}" type="textarea" placeholder="请输入动态内容" maxlength="2000" show-word-limit></el-input>
          </el-form-item>
        </el-form>
        <div style="	display: flex;	align-items: center;justify-content:flex-end;" class="moving">
          <imagePreview :imageUrls="form.imageUrls" />
          <span @click="uploadDialogShow">添加图片</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submit" size="mini">确 定</el-button>
      </span>
    </el-dialog>
    <uploadDialog ref="uploadDialog" :limit="9" tip="只能上传jpg/png文件" @success="uploadDialogSuccess" />
  </div>
</template>
 <script>
import uploadDialog from "@/components/uploadDialog"
import imagePreview from "@/components/imagePreview"
export default {
  components: {
    uploadDialog,
    imagePreview
  },
  data () {
    return {
      dialogVisible: false,
      form: {
        content: '',
        imageUrls: ''
      }
    };
  },
  mounted () { },
  methods: {
    show () {
      this.dialogVisible = true;
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postTrend()
        } else {
          return false
        }
      })
    },
    uploadDialogShow () {
      this.$refs.uploadDialog.show()
    },
    uploadDialogSuccess (arr) {
      this.form.imageUrls = arr.toString()
    },
    async postTrend () {
      const { status, info } = await this.$api.postTrend({ ...this.form, companyUuid: this.$route.params.uuid })
      if (status === 200) {
        this.$message({
          message: info,
          type: 'success'
        })
        this.$emit('success');
        this.dialogVisible = false
      } else {
        this.$message({
          message: info,
          type: 'error'
        })
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>